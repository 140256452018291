import React, { useState, useEffect } from 'react';
import {
  Link,
} from 'react-router-dom'

const PageTest2 = ({ formData, handleInputChange }) => {


  useEffect(() => {
    // Function to fetch products from the API
 
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (

                <section id="pageTest1" className="hidden pageTest introduction-effect central-text">



                <h2 class="test-title">Quelle est votre style ?</h2>

                <h3>Quelle serait votre destination de vacances préférée ? </h3>

                <div className="central-content">
                <div className="choices-test grider-3 uppercaser">

                    <label for="q1a1" data-question="1"  class="choice orangecolored justifier selected"><h4>Londres</h4><input type="radio" id="q1a1" value="Londres" name="question1" onChange={handleInputChange}  checked /></label>
                    <label for="q1a2" data-question="1"  class="choice justifier"><h4>New york</h4><input type="radio" id="q1a2" value="New york" name="question1" onChange={handleInputChange} /></label>
                    <label for="q1a3" data-question="1"  class="choice orangecolored justifier"><h4>Rome</h4><input type="radio" id="q1a3" value="Rome"  name="question1" onChange={handleInputChange}/></label>
                    <label for="q1a4" data-question="1"  class="choice justifier"><h4>Saint Barth</h4><input type="radio" id="q1a4" value="Saint Barth"  name="question1" onChange={handleInputChange}/></label>
                    <label for="q1a5" data-question="1"  class="choice orangecolored justifier"><h4>Varsovie</h4><input type="radio" id="q1a5"  value="Varsovie" name="question1" onChange={handleInputChange}/></label>
                    <label for="q1a6" data-question="1"  class="choice justifier"><h4>Laponie</h4><input type="radio" id="q1a6" value="Laponie"  name="question1" onChange={handleInputChange}/></label>
                </div>


                </div>
               <div class="nav-panel central-text"> <span class="movePage material-symbols-outlined" data-page="0">chevron_left</span> <span>1 / 5</span> <span class="movePage material-symbols-outlined" data-page="2">chevron_right</span>  </div>



            </section>
  );
};


export default PageTest2