import React, { useState, useEffect } from 'react';
import Test from '../Test.js';
import {
  Link,
} from 'react-router-dom'

const PageTest6 = ({ formData, handleInputChange }) => {
  const [services, setServices] = useState([]);
  const [homepage, setHomepage] = useState([]);

  useEffect(() => {
    // Function to fetch products from the API
    const fetchServices = async () => {
      try {
        // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
        const version = await fetch('https://levelupwithkeke.com//json_manager/getVersions.php');
        const versionData = await version.json();        
        const response = await fetch(`/data/performance_${versionData.performance}.json`);
        const data = await response.json();
        const homepageResponse = await fetch(`/data/homepage_${versionData.homepage}.json`);
        const homepageData = await homepageResponse.json();
        setServices(data); // Update state with fetched services
        setHomepage(homepageData); // Update state with fetched services
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchServices(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (

                <section id="pageTest6" className="hidden pageTest introduction-effect central-text">



                <h2 class="test-title">Quelle est votre style ?</h2>

                <h3>Merci pour vos réponses !</h3>
                

                <div className="central-content">



                    <div class="success-checkmark">
                      <div class="check-icon">
                        <span class="icon-line line-tip"></span>
                        <span class="icon-line line-long"></span>
                        <div class="icon-circle"></div>
                        <div class="icon-fix"></div>
                      </div>
                    </div>

                  <h4 class="uppercaser">Entrez votre adresse email et recevez sous 48h les résultats de votre test de style </h4>

                    
                    <input class="marger-bottom" type="text" name="email" onChange={handleInputChange} required/><br></br>



                    <div class="nav-panel central-text"> <span class="movePage material-symbols-outlined" data-page="5">chevron_left</span>                     <input class="marger-bottom" type="submit" name="test" value="Envoyer"/>  <span class="movePage invisible material-symbols-outlined" >chevron_right</span> </div>

 



                </div>



            </section>
  );
};


export default PageTest6