import React, { useState, useEffect } from "react";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [index, setIndex] = useState(1); // Index technique pour la boucle infinie
  const [realIndex, setRealIndex] = useState(0); // Index visible pour l'utilisateur
  const [transition, setTransition] = useState(true);
  const intervalTime = 3000; // Temps entre chaque transition automatique
  let autoPlay = true; // Garde la lecture automatique active

  useEffect(() => {
    // Fonction pour récupérer les témoignages
    const fetchTestimonials = async () => {
      try {
        const version = await fetch("https://levelupwithkeke.com/json_manager/getVersions.php");
        const versionData = await version.json();
        const response = await fetch(`/data/testimonials_${versionData.testimonials}.json`);
        const data = await response.json();
        setTestimonials(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des témoignages :", error);
      }
    };

    fetchTestimonials();
  }, []);

  // Création du tableau étendu pour la boucle infinie
  const extendedTestimonials = testimonials.length > 0
    ? [testimonials[testimonials.length - 1], ...testimonials, testimonials[0]]
    : [];

  // Fonction pour passer au slide suivant
  const nextSlide = () => {
    if (index >= extendedTestimonials.length - 1) {
      setTimeout(() => {
        setTransition(false);
        setIndex(1); // Reset instantané
      }, 500);
      setRealIndex(0); // Synchronisation immédiate des boutons radio
    } else {
      setTransition(true);
      setIndex((prev) => prev + 1);
      setRealIndex((prev) => (prev + 1) % testimonials.length);
    }
  };

  // Fonction pour passer au slide précédent
  const prevSlide = () => {
    if (index <= 0) {
      setTimeout(() => {
        setTransition(false);
        setIndex(extendedTestimonials.length - 2); // Reset instantané à la fin
      }, 500);
      setRealIndex(testimonials.length - 1); // Synchronisation immédiate des boutons radio
    } else {
      setTransition(true);
      setIndex((prev) => prev - 1);
      setRealIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
    }
  };

  // Fonction pour aller directement à un slide en cliquant sur un bouton radio
  const goToSlide = (slideIndex) => {
    setTransition(true);
    setIndex(slideIndex + 1); // Ajustement avec l'index technique
    setRealIndex(slideIndex); // Mise à jour immédiate du bouton radio
  };

  // Défilement automatique
  useEffect(() => {
    if (!autoPlay) return;
    const timer = setInterval(nextSlide, intervalTime);
    return () => clearInterval(timer);
  }, [index]);

  return (
    <section className="full-h shower" id="testimonials">
      <div className="full central-content">
        <h2 className="white-text">Elles m'ont fait confiance</h2>
        <div className="content central-text">
          <div className="organizer white-text">
            <nav id="carouselControler">
              <span onClick={prevSlide} className="material-symbols-outlined carouselControl">
                arrow_back_ios
              </span>
              {testimonials.map((_, i) => (
                <span
                  key={i}
                  onClick={() => goToSlide(i)} // Sélection directe du slide
                  className={`material-symbols-outlined radio-carousel ${
                    realIndex === i ? "active" : ""
                  }`}
                >
                  {realIndex === i ? "radio_button_checked" : "radio_button_unchecked"}
                </span>
              ))}
              <span onClick={nextSlide} className="material-symbols-outlined carouselControl">
                arrow_forward_ios
              </span>
            </nav>
          </div>

          {/* CAROUSEL */}
          <div className="carousel-container">
            <div
              className="carousel-track"
              style={{
                transform: `translateX(-${index * 100}%)`,
                transition: transition ? "transform 0.5s ease-in-out" : "none"
              }}
            >
              {extendedTestimonials.map((testimonial, i) => (
                <div key={i} className="before-after centralizer">
                  <h3>Photos de {testimonial.name}</h3>
                  <div className="padder organizer grider-2">
                    <div className="column central-text uppercaser">
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${testimonial.beforePicture})` }}
                      ></div>
                      <h4>Avant</h4>
                    </div>
                    <div className="column central-text uppercaser">
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${testimonial.afterPicture})` }}
                      ></div>
                      <h4>Après</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div id="notices">
              <div className="grider padder">
                {testimonials.map((testimonial) => (
                  <div key={testimonial.id} id={`notice-${testimonial.id}`} className="notice">
                    <div className="rate">
                      {[...Array(5)].map((_, i) => (
                        <span key={i} className="material-icons">
                          {testimonial.rate >= i + 1 ? 'star' : 'star_outline'}
                        </span>
                      ))}
                    </div>
                    <h4>{testimonial.name}</h4>
                    <p>{testimonial.comment}</p>
                  </div>
                ))}
              </div>
            </div>


        </div>
      </div>
    </section>
  );
};

export default Testimonials;
