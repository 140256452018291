import React, { useState, useEffect } from 'react';
import {
  Link,
} from 'react-router-dom'

const PageTest6 = ({ formData, handleInputChange }) => {
  const [services, setServices] = useState([]);
  const [homepage, setHomepage] = useState([]);

  useEffect(() => {
    // Function to fetch products from the API
    const fetchServices = async () => {
      try {
        // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
        const version = await fetch('https://levelupwithkeke.com//json_manager/getVersions.php');
        const versionData = await version.json();        
        const response = await fetch(`/data/performance_${versionData.performance}.json`);
        const data = await response.json();
        const homepageResponse = await fetch(`/data/homepage_${versionData.homepage}.json`);
        const homepageData = await homepageResponse.json();
        setServices(data); // Update state with fetched services
        setHomepage(homepageData); // Update state with fetched services
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchServices(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (

                <section id="pageTest5" className="hidden pageTest  introduction-effect central-text">



                <h2 class="test-title">Quelle est votre style ?</h2>

                <h3>Vos vêtements sont pour vous un moyen de ...</h3>

                <div className="central-content">
                <div className="choices-test grider-3 uppercaser">

                    <label for="q5a1" data-question="5"  class="choice bluecolored justifier selected"><h4>de perpétuer le style familial </h4><input type="radio" id="q5a1" value="de perpétuer le style familial" name="question5" onChange={handleInputChange} checked/></label>
                    <label for="q5a2" data-question="5"  class="choice justifier"><h4>d’avoir du confort</h4><input type="radio" id="q5a2" value="d’avoir du confort" name="question5" onChange={handleInputChange}/></label>
                    <label for="q5a3" data-question="5"  class="choice bluecolored justifier"><h4>de mettre du rêve dans votre vie</h4><input type="radio" id="q5a3" value="de mettre du rêve dans votre vie" name="question5" onChange={handleInputChange}/></label>
                    <label for="q5a4" data-question="5"  class="choice justifier"><h4>d’affirmer votre séduction</h4><input type="radio" id="q5a4" value="d’affirmer votre séduction" name="question5" onChange={handleInputChange}/></label>
                    <label for="q5a5" data-question="5"  class="choice bluecolored justifier"><h4>d’être habillée</h4><input type="radio" id="q5a5" value="d’être habillée" name="question5" onChange={handleInputChange}/></label>
                    <label for="q5a6" data-question="5"  class="choice justifier"><h4>d’exprimer votre créativité</h4><input type="radio" id="q5a6" value="d’exprimer votre créativité" name="question5" onChange={handleInputChange}/></label>
                </div>


                </div>
                <div class="nav-panel central-text"> <span class="movePage material-symbols-outlined" data-page="4">chevron_left</span> <span>5 / 5</span> <span class="movePage material-symbols-outlined" data-page="6">chevron_right</span>  </div>




            </section>
  );
};


export default PageTest6