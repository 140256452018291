import React, { useState, useEffect } from 'react';
import {
  Link,
} from 'react-router-dom'

const PageTest4 = ({ formData, handleInputChange }) => {
  const [services, setServices] = useState([]);
  const [homepage, setHomepage] = useState([]);

  useEffect(() => {
    // Function to fetch products from the API
    const fetchServices = async () => {
      try {
        // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
        const version = await fetch('https://levelupwithkeke.com//json_manager/getVersions.php');
        const versionData = await version.json();        
        const response = await fetch(`/data/performance_${versionData.performance}.json`);
        const data = await response.json();
        const homepageResponse = await fetch(`/data/homepage_${versionData.homepage}.json`);
        const homepageData = await homepageResponse.json();
        setServices(data); // Update state with fetched services
        setHomepage(homepageData); // Update state with fetched services
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchServices(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (

                <section id="pageTest3" className="hidden pageTest  introduction-effect central-text">



                <h2 class="test-title">Quelle est votre style ?</h2>

                <h3>Votre maquillage ... </h3>

                <div className="central-content">
                <div className="choices-test grider-3 uppercaser">

                    <label for="q3a1" data-question="3"  class="choice purplecolored justifier selected"><h4>secondaire et léger</h4><input type="radio" id="q3a1" value="Secondaire et léger" name="question3" onChange={handleInputChange} checked/></label>
                    <label for="q3a2" data-question="3"  class="choice justifier"><h4>simple mais parfaitement réalisé</h4><input type="radio" id="q3a2" value="Simple mais parfaitement réalisé" name="question3" onChange={handleInputChange}/></label>
                    <label for="q3a3" data-question="3"  class="choice purplecolored justifier"><h4>léger, souvent coloré, gloss</h4><input type="radio" value="Léger, souvent coloré, gloss" id="q3a3" name="question3" onChange={handleInputChange}/></label>
                    <label for="q3a4" data-question="3"  class="choice justifier"><h4>appuyé en camaïeux, lèvres très maquillées </h4><input type="radio" value="Appuyé en camaïeux, lèvres très maquillées" id="q3a4" name="question3" onChange={handleInputChange}/></label>
                    <label for="q3a5" data-question="3"  class="choice purplecolored justifier"><h4>occasionnel et par petites touches </h4><input type="radio" value="Occasionnel et par petites touches" id="q3a5" name="question3" onChange={handleInputChange}/></label>
                    <label for="q3a6" data-question="3"  class="choice justifier"><h4>le minimum la journée, très marQUÉ LE SOIR </h4><input type="radio" value="Le minimum la journée, très marQUÉ LE SOIR " id="q3a6" name="question3" onChange={handleInputChange}/></label>
                </div>


                </div>
                <div class="nav-panel central-text"> <span class="movePage material-symbols-outlined" data-page="2">chevron_left</span> <span>3 / 5</span> <span class="movePage material-symbols-outlined" data-page="4">chevron_right</span>  </div>




            </section>
  );
};


export default PageTest4