import React, { useState, useEffect } from 'react';
import {
  Link,
} from 'react-router-dom'

const PageTest3 = ({ formData, handleInputChange }) => {
  const [services, setServices] = useState([]);
  const [homepage, setHomepage] = useState([]);

  useEffect(() => {
    // Function to fetch products from the API
    const fetchServices = async () => {
      try {
        // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
        const version = await fetch('https://levelupwithkeke.com//json_manager/getVersions.php');
        const versionData = await version.json();        
        const response = await fetch(`/data/performance_${versionData.performance}.json`);
        const data = await response.json();
        const homepageResponse = await fetch(`/data/homepage_${versionData.homepage}.json`);
        const homepageData = await homepageResponse.json();
        setServices(data); // Update state with fetched services
        setHomepage(homepageData); // Update state with fetched services
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchServices(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (

                <section id="pageTest2" className="hidden pageTest  introduction-effect central-text">



                <h2 class="test-title">Quelle est votre style ?</h2>

                <h3>Des kilos superflus ? Votre réaction ?  ? </h3>

                <div className="central-content">
                <div className="choices-test grider-3 uppercaser">

                    <label for="q2a1" data-question="2"  class="choice greencolored justifier selected"><h4>pas besoin de regime, pas de kilos superflus</h4><input type="radio" id="q2a1" value="Pas besoin de regime, pas de kilos superflus" name="question2" onChange={handleInputChange} checked/></label>
                    <label for="q2a2" data-question="2"  class="choice justifier"><h4>vous vous mettez au régime, toujours le même</h4><input type="radio" id="q2a2" value="Vous vous mettez au régime, toujours le même" name="question2" onChange={handleInputChange}/></label>
                    <label for="q2a3" data-question="2"  class="choice greencolored justifier"><h4>regime : amour et eau fraiche</h4><input type="radio" id="q2a3" value="Regime : amour et eau fraiche" name="question2" onChange={handleInputChange}/></label>
                    <label for="q2a4" data-question="2"  class="choice justifier"><h4>sport et crèmes miracles</h4><input type="radio" id="q2a4" value="Sport et crèmes miracles" name="question2" onChange={handleInputChange}/></label>
                    <label for="q2a5" data-question="2"  class="choice greencolored justifier"><h4>en douceur, vous mangez équilibréen douceur, vous mangez équilibré</h4><input type="radio" id="q2a5" value="en douceur, vous mangez équilibréen douceur, vous mangez équilibré" onChange={handleInputChange} name="question2"/></label>
                    <label for="q2a6" data-question="2"  class="choice justifier"><h4>dernier régime a la mode de votre magazine</h4><input type="radio" id="q2a5" value="Dernier régime a la mode de votre magazine" onChange={handleInputChange} name="question2"/></label>
                </div>


                </div>
                <div class="nav-panel central-text"> <span class="movePage material-symbols-outlined" data-page="1">chevron_left</span> <span>2 / 5</span> <span class="movePage material-symbols-outlined" data-page="3">chevron_right</span>  </div>



            </section>
  );
};


export default PageTest3