// utils/LoadScript.js

import $ from 'jquery';


export function submitPopup() {

  $('#contactForm').on('submit', () => {


      $("#popupSubmit .popup").removeClass("leashed");
      $("#popupSubmit").removeClass("closed");


  });

  return () => {
    $('#popupSubmit').off('click');
  };

}
