import React, { useState, useEffect } from "react";
import $ from "jquery";
import Popup from "./PopupSubmit.js";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    surname: "",
    email: "",
    message: "",
  });

  const [message, setMessage] = useState(""); // Message de confirmation/erreur



  // Gestion des inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Gestion de l'envoi du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    $("#loader").removeClass("invisible");

    setMessage(""); // Réinitialiser le message

    try {
      console.log("Données envoyées :", JSON.stringify(formData));

      const response = await fetch("https://cuid.levelupwithkeke.com/api/data/submit/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setMessage("Votre réservation a bien été enregistrée");

        // ✅ Réinitialisation des inputs contrôlés par React
        setFormData({
          firstname: "",
          surname: "",
          email: "",
          message: "",
        });

        // ✅ Réinitialisation du formulaire en HTML

        document.getElementById("contactForm").reset();
        $("#loader").addClass("invisible");

        // ✅ Afficher le popup

        $("#popupSubmit").removeClass("popup-closed");
        $("#popupSubmit .popup").removeClass("leashed");

      } else {
        console.error("Échec de l'envoi");
        setMessage("Erreur lors de la réservation");
      }
    } catch (error) {
      console.error("Erreur réseau :", error);
      setMessage("Une erreur est survenue");
    }
  };

  return (
    <React.Fragment>
      <section className="full-h" id="contact">
        <div id="contact-form" className="flexible">

          <div id="pro-infos">
            <div className="container">
              <div id="pro-photo" className="uppercaser"></div>
              <h4 className="uppercaser">Ketsia Ngbilo</h4>

              <ul>
                <li>06 06 06 06 06</li>
                <li>levelupwithkeke@gmail.com</li>
              </ul>

              <h4 className="uppercaser">Suivez-moi</h4>
              <a className="social" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/glowydent/">
                <img className="logo-mini invert" src="/media/image/icons/Instagram.png" alt="Instagram" />
              </a>
              <a className="social" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/glowydent-professional-8279392bb/">
                <img className="logo-mini invert" src="/media/image/icons/Linkedin.png" alt="Linkedin" />
              </a>
              <a className="social" target="_blank" rel="noopener noreferrer" href="https://sofifa.com/league/31">
                <img className="logo-mini invert" src="/media/image/icons/TikTok.png" alt="TikTok" />
              </a>
              <a className="social" target="_blank" rel="noopener noreferrer" href="https://sofifa.com/league/31">
                <img className="logo-mini invert" src="/media/image/icons/Pinterest.png" alt="Pinterest" />
              </a>
            </div>
          </div>

          <div id="customer-infos">
            
            <form id="contactForm" method="POST" onSubmit={handleSubmit}>
              <div className="padder">
                <h4 className="uppercaser">Formulaire de contact</h4>
                {/* Affichage du message de succès/erreur */}
                {message && <p className="status-message green-text">{message}</p>}
                <ul>
                  <li>
                    <label>
                      <input
                        type="text"
                        placeholder="ENTREZ VOTRE PRÉNOM"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                  </li>

                  <li>
                    <label>
                      <input
                        type="text"
                        placeholder="ENTREZ VOTRE NOM"
                        name="surname"
                        value={formData.surname}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                  </li>

                  <li>
                    <label>
                      <input
                        type="text"
                        placeholder="ENTREZ VOTRE EMAIL"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                  </li>

                  <li>
                    <label>
                      <textarea
                        name="message"
                        placeholder="ENTREZ VOTRE MESSAGE"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </label>
                  </li>
                </ul>

                <p className="central-text">
                  <input type="submit" id="bookingSubmitFormButton" name="booking" value="ENVOYER" />
                </p>


              </div>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Contact;
