import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, useParams } from 'react-router-dom';
import { sliderScript } from '../utils/JQuery/slider.js';
import $ from 'jquery';

const Banner = () => {
  const location = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { name } = useParams();
  const [title, setTitle] = useState("");
  const [homeData, setHomeData] = useState([]);
  const [banner, setBannerData] = useState([]);



  // Handle default name value
  const pageName = name || "homepage";


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setData({});



      try {

        const version = await fetch('https://levelupwithkeke.com//json_manager/getVersions.php'); //This API it's a PHP code return a jSon with the number latest version of json file data website_XXXX.json
        const versionData = await version.json();
        const homepage = await fetch('/data/homepage_'+versionData.homepage+'.json');
        const banner = await fetch('/data/banner_'+versionData.homepage+'.json');


        if(pageName != "homepage"){
        const response = await fetch(`/data/page_${name}_${versionData[`page_${name}`]}.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');

        }

        const result = await response.json();
        setData(result);
        }

        const resultHomeData = await homepage.json();
        const resultBannerData = await banner.json();

        setHomeData(resultHomeData);
        setBannerData(resultBannerData);


        setError(null);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const intervalId = sliderScript();

    return () => {
      clearInterval(intervalId);
    };

  }, [name]);

  useEffect(() => {
    if (data.title) {
      setTitle(`<h1 class="white-text introduction-effect">${data.title}</h1>`);
    }
  }, [data]);



  const getImagePath = (imageName) => {
    return `/media/image/${imageName}`;
  };

  return (
    <>
      {location.pathname === '/' && (


        <div id="banner">

                        {banner.map((banner) => (
                          <style>
                            {`
                              .slide${banner.id} {
                                background-image: url(${banner.image});
                              }

                              .slide${banner.id} h1::before {
                                content: "${banner.title}";
                              }
                            `}
                          </style>
                        ))}

          <div id="slide" className="slide1">
            <div className="bannerInfo padder">
              <h1 className="introduction-effect white-text slideTitle1"></h1>
              <div className="introduction-effect-up" id="bookingNow">
              <button className="uppercaser">Prendre rendez-vous</button>
            </div>
            </div>
            
            <div class="organizer white-text">
                <nav id="slideControler">
                  <span data-control="-1" class="material-symbols-outlined slideControl">arrow_back_ios</span>
                  <span id="radio-slide-1" class="material-symbols-outlined radio-slide">radio_button_checked</span>
                  <span id="radio-slide-2"  class="material-symbols-outlined radio-slide">radio_button_unchecked</span>
                  <span id="radio-slide-3"  class="material-symbols-outlined radio-slide">radio_button_unchecked</span>
                  <span data-control="1" class="material-symbols-outlined slideControl">arrow_forward_ios</span>
                </nav>
            </div>


          </div>


          <div id="bannerBottom" className="uppercaser">Découvrez enfin votre style ! Faites dès maintenant notre test de style rapide et gratuit ici</div>

        </div>
      )}



      {location.pathname === '/page/apprendre-a-marcher-en-talons' && (
        <div id="simple-banner">
          <div id="bigtitle">
            <div className="padder">
              <div className="banner-title central-text" dangerouslySetInnerHTML={{ __html: title }}></div>
            </div>
          </div>
        </div>


      )}        

    </>
  );
};

export default Banner;
