// utils/LoadScript.js
import $ from 'jquery';

export function popupScript() {



        $('.viewpopup-test').on('mouseover', () => {

            $("#popup").removeClass("popup-closed");

            $("#footer-menu").removeClass("viewpopup-test");

        setTimeout(() => {
            $(".popup").removeClass("leashed");
          }, 1);


        });


        $('#popup-closer').on('click', () => {

            $("#popup .popup").addClass("leashed");
            $("#popup").addClass("popup-closed");

            $("#footer-menu").removeClass("viewpopup-test");
            setTimeout(() => {
                $("#popup").addClass("hidden");
              }, 2);
    

        });

        $('#popupSubmit-closer').on('click', () => {

            $("#popupSubmit .popup").addClass("leashed");
            $("#popupSubmit").addClass("popup-closed");
            $("#footer-menu").removeClass("viewpopup-test");

        });


        $('#start-test').on('click', () => {

            $("#popup .popup").addClass("leashed");
            $("#popup").addClass("closed");
            $("#footer-menu").removeClass("viewpopup-test");

        });

        

}
