import React from 'react';

const Loader = () => {


  return (
    

              <div id="loader" class="transitioner">

            <div id="loadIcon">
            <div class="lds-ripple"><div></div><div></div></div>       
            <img class="invert" src="/media/image/icons/logo.png" />
            <p class="white-text central-text">Powered by <img class="mini-logo invert" src="/media/image/icons/aienova.png"/> </p>
            </div>




          </div>

  );
};

export default Loader;