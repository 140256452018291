import React, { useState, useEffect } from 'react';
import { test } from '../../utils/JQuery/test.js';
import {
  Link,
} from 'react-router-dom'

const PageTest1 = () => {


  useEffect(() => {

            test();
    // Function to fetch products from the API

  }, []); // Empty dependency array ensures the effect runs once on mount

  return (

                <section id="pageTest0" className="introduction-effect  pageTest">



                <h1 class="central-content">Quelle est votre style ?</h1>

                <div id="banner-test"></div>
               <div class="central-text"> <button class="movePage" data-page="1">Commencer le test</button></div>



            </section>
  );
};


export default PageTest1