import React from 'react';

const PopupSubmit = () => {


  return (

              <div id="popupSubmit" class=" transitioner popup-closed justifier">


                  <div class="popup transitioner leashed">


                    <div className="padder black-text">

                    <div  className="right-text"><span id="popupSubmit-closer" class="material-symbols-outlined clickable">cancel</span></div>

                    <h3>Votre demande a bien été envoyé</h3>

                    <h4 className="uppercaser">Nous vous remercions pour votre confiance, nous vous répondrons dans les plus bref délai</h4>

                  </div>

                  </div>

          </div>

  );
};

export default PopupSubmit;