import $ from 'jquery';

export function test() {
  // Gestionnaire d'événements pour chaque bouton
  $('.movePage').on('click', function () {
    // Récupère la valeur de l'attribut data-page de l'élément cliqué
    const i = $(this).attr('data-page');

    console.log("PageTest" + i);

    // Masquer toutes les pages de test
    $(".pageTest").addClass("hidden"); // Masquer toutes les pages (pas seulement celles avec l'index i)

    // Afficher uniquement la page de test correspondante
    $("#pageTest" + i).removeClass("hidden");
  });

  for (let i = 0; i <= 6; i++) {
    for (let y = 0; y <= 6; y++) {


    // Gestionnaire d'événements pour chaque bouton
    $('#q'+ i +"a"+y).on('click', () => {

        $("label[data-question='"+i+"'").removeClass("selected");

        $(" label[for='q"+i+"a"+y+"']").addClass("selected");

    });


     }
    }



}
