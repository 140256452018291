// utils/LoadScript.js
import $ from 'jquery';

export function sliderScript() {
    let i = 1;  // Index actuel du slide
    const totalSlides = 3;
    let intervalId;

    function move(newIndex) {
        // Gestion des limites pour éviter les valeurs incorrectes
        if (newIndex < 1) {
            newIndex = totalSlides; // Si on est avant le premier, on revient au dernier
        } else if (newIndex > totalSlides) {
            newIndex = 1; // Si on dépasse le dernier, on revient au premier
        }

        // Mise à jour du slider
        $("#slide").removeClass(function (index, className) {
            return (className.match(/(^|\s)slide\d+/g) || []).join(" ");
        });


        $("#slide").addClass("slide" + newIndex);

        i = newIndex; // Mise à jour de l'index actuel


        $(".radio-slide").html("radio_button_unchecked");
        $("#radio-slide-"+i).html("radio_button_checked");

        return i;
    }

    function nextSlide() {
      let num = move(i + 1);
        resetInterval();
        return num;
    }

    function prevSlide() {
        let num = move(i - 1);
        resetInterval();
        return num;
    }

    function resetInterval() {
        clearInterval(intervalId);
        intervalId = setInterval(nextSlide, 6000);
    }

    // Défilement manuel avec les boutons
    $(".slideControl").on("click", function () {
        let control = parseInt($(this).attr("data-control"));

        if (control === 1) {
            nextSlide();
        } else if (control === -1) {
            prevSlide();
        }
    });


            for(let x=1;x<=3;x++){

              $("#radio-slide-"+x).on("click", function () {

                move(x);
                resetInterval();

            });


            }



    // Défilement automatique
    intervalId = setInterval(nextSlide, 6000);

    return intervalId;
}
