import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import $ from 'jquery';
import { Helmet } from 'react-helmet';


const Service = () => {
  const [service, setServiceData] = useState({});
  const [website, setWebsiteData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { name } = useParams();

  useEffect(() => {
    $(window).scrollTop(0);
    $(".hiding").removeClass("hiding"); // Fix class selector
    $("header").removeClass("hidden"); // Fix class selector
    $("footer").removeClass("hidden"); // Fix class selector

    const fetchData = async () => {
      setLoading(true);
      try {
        const version = await fetch('https://levelupwithkeke.com//json_manager/getVersions.php');
        const versionData = await version.json();
        const websiteResponse = await fetch(`/data/website_${versionData.website}.json`);
        const ServiceResponse = await fetch(`/data/performance_${name}_${versionData[`performance_${name}`]}.json`);
        const ServiceData = await ServiceResponse.json();
        const websiteData = await websiteResponse.json();
        setServiceData(ServiceData);
        setWebsiteData(websiteData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [name]);

  if (loading) {
    return (
      <section id="the-service">
        <div className="container">
          <div className="padder">
           
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const renderContent = () => {

      return (
        <section id="the-service" className="introduction-effect">
          <div className="container">
            <div className="padder">
            <h1>{service.name }</h1>

              <div id="service-content" class="central-content" dangerouslySetInnerHTML={{ __html: service.content }} />
            </div>
          </div>

          <div id="style-test" className="justifier central-text"><h2>Faîtes votre test de style , redécouvrez qui vous êtes </h2><Link to="/service/quel-est-votre-style"><button>Faire un test</button></Link></div>

        </section>

        
      );
    }
  

  return (
    <>
      <Helmet>
        <title>{service.name || "Default Title"}</title>
        <meta name="description" content={service.description || "Default description"} />
      </Helmet>
      {renderContent()}
    </>
  );
};

export default Service;
