import React, { useState, useEffect } from 'react';
import {
  Link,
} from 'react-router-dom'

const PageTest5 = ({ formData, handleInputChange }) => {
  const [services, setServices] = useState([]);
  const [homepage, setHomepage] = useState([]);

  useEffect(() => {
    // Function to fetch products from the API
    const fetchServices = async () => {
      try {
        // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
        const version = await fetch('https://levelupwithkeke.com//json_manager/getVersions.php');
        const versionData = await version.json();        
        const response = await fetch(`/data/performance_${versionData.performance}.json`);
        const data = await response.json();
        const homepageResponse = await fetch(`/data/homepage_${versionData.homepage}.json`);
        const homepageData = await homepageResponse.json();
        setServices(data); // Update state with fetched services
        setHomepage(homepageData); // Update state with fetched services
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchServices(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (

                <section id="pageTest4" className="hidden pageTest  introduction-effect central-text">



                <h2 class="test-title">Quelle est votre style ?</h2>

                <h3>Vous conseillez une robe de mariée à votre amie ... </h3>

                <div className="central-content">
                <div className="choices-test grider-3 uppercaser">

                    <label for="q4a1" data-question="4"  class="choice pinkcolored justifier selected"><h4>une robe sobre et blanche</h4><input type="radio" id="q4a1" value="Une robe sobre et blanche" name="question4" onChange={handleInputChange} checked/></label>
                    <label for="q4a2" data-question="4"  class="choice justifier"><h4>champagne, avec des motifs discrets</h4><input type="radio" id="q4a2" value="Champagne, avec des motifs discrets" name="question4" onChange={handleInputChange}/></label>
                    <label for="q4a3" data-question="4"  class="choice pinkcolored justifier"><h4>a dentelle ou mousseline</h4><input type="radio" id="q4a3" value="a dentelle ou mousseline" name="question4" onChange={handleInputChange}/></label>
                    <label for="q4a4" data-question="4"  class="choice justifier"><h4>une robe fourreau très feminine</h4><input type="radio" id="q4a4" value="Une robe fourreau très feminine" name="question4" onChange={handleInputChange}/></label>
                    <label for="q4a5" data-question="4"  class="choice pinkcolored justifier"><h4>esprit champêtre accessoirisée d’un chapeau</h4><input type="radio" value="esprit champêtre accessoirisée d’un chapeau" id="q4a5" name="question4" onChange={handleInputChange}/></label>
                    <label for="q4a6" data-question="4"  class="choice justifier"><h4>originale et colorée </h4><input type="radio" id="q4a6" value="originale et colorée" name="question4"/></label>
                </div>


                </div>
                <div class="nav-panel central-text"> <span class="movePage material-symbols-outlined" data-page="3">chevron_left</span> <span>4 / 5</span> <span class="movePage material-symbols-outlined" data-page="5">chevron_right</span>  </div>




            </section>
  );
};


export default PageTest5