import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loader from './Loader';
import Booking from './Booking';
import $ from 'jquery';
import Faqs from './Faq';
import { Helmet } from 'react-helmet';
import Services from './Services';
import Contact from './Contact';
import Workshops from './Workshops.js';
import Advices from './Advices.js';
import Newsletter from './Newsletter';
import Lessons from './Lessons.js';
import PageLoader from './PageLoader';
import PageTest1 from './Test/pageTest1.js';
import PageTest2 from './Test/pageTest2.js';
import PageTest3 from './Test/pageTest3.js';
import PageTest4 from './Test/pageTest4.js';
import PageTest5 from './Test/pageTest5.js';
import PageTest6 from './Test/pageTest6.js';
import PageTest7 from './Test/pageTest7.js';
import { test } from '../utils/JQuery/test.js';


const Test = () => {
  const [Test, setTestData] = useState({});
  const [website, setWebsiteData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { testid } = useParams();
    const [status, setStatus] = useState(''); // For success or error messages
      const [message, setMessage] = useState('');

        const [formData, setFormData] = useState({
          question1: '',
          question2: '',
          question3: '',
          question4: '',
          question5: '',
          email: '',
        });
  
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    

  useEffect(() => {
    $(window).scrollTop(0);
    $(".hiding").removeClass("hiding"); // Fix class selector


    $("header").addClass("hidden"); // Fix class selector
    $("footer").addClass("hidden"); // Fix class selector
    $("#app-content").addClass("testing"); // Fix class selector
        test();

    const fetchData = async () => {
      setLoading(true);
      try {
        const version = await fetch('https://levelupwithkeke.com//json_manager/getVersions.php');
        const versionData = await version.json();
        const websiteResponse = await fetch(`/data/website_${versionData.website}.json`);
        const websiteData = await websiteResponse.json();

        setWebsiteData(websiteData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [testid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(''); // Reset status message
  
    try {
      console.log(JSON.stringify(formData)); // Debug : vérifier le contenu des données
      const response = await fetch('https://cuid.levelupwithkeke.com/api/data/submit/test', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Remplacez `data` par `body`
      });
  
      if (response.ok) {
        const data = await response.json();
        setMessage("Votre réservation a bien été enregistrée");

      } else {
        console.error('Failed to submit booking');
        setMessage("Erreur lors de la réservation");
      }
    } catch (error) {
      console.error('Error submitting booking:', error);
    }
  };
  

  if (loading) {
    return (
      <section id="the-page">
        <div className="container">
          <div className="padder">
            <PageLoader />
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const renderContent = () => {


    if (testid === 1) {
      return (
        <section id="the-page" className="introduction-effect">
          <div className="container">
            <div className="padder">


              <h1 class="central-content">Quelle est votre style ?</h1>



            </div>
          </div>

        </section>
      );
    } 
    
    else {
      return (


        <div id="pagesTest">

            <a href="/"><img class="pastille" src="https://cuid.levelupwithkeke.com/cuid/media/image/09-11-2024/pastille_7190044703.png" /></a>
          <form method="POST" onSubmit={handleSubmit}>
            <PageTest1 formData={formData} handleInputChange={handleInputChange} />
            <PageTest2 formData={formData} handleInputChange={handleInputChange} />
            <PageTest3 formData={formData} handleInputChange={handleInputChange} />
            <PageTest4 formData={formData} handleInputChange={handleInputChange} />
            <PageTest5 formData={formData} handleInputChange={handleInputChange} />
            <PageTest6 formData={formData} handleInputChange={handleInputChange}/>
            <PageTest7 formData={formData} handleInputChange={handleInputChange} />
          </form>
        </div>



        
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Trouvez votre style</title>
        <meta name="description" content="Faîte un test de personnalité pour trouver votre style" />
      </Helmet>
      {renderContent()}
    </>
  );
};

export default Test;
